
import { SAMLAuthProvider, UserCredential } from '@firebase/auth'
import { FormController, IVForm, validationRules } from '@icepanel/app-form'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Ref } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import * as appAnalytics from '@/helpers/analytics'
import * as firebase from '@/plugins/firebase'

import * as analytics from '../helpers/analytics'
import * as authListener from '../helpers/auth-listener'
import { UserModule } from '../store'
import UserRegister from './register.vue'

@Component({
  name: 'UserRegisterSAML'
})
export default class extends Vue {
  userModule = getModule(UserModule, this.$store)

  formInteracted = false

  @Ref() readonly form!: IVForm

  formController = new FormController({
    initialModel: {
      acceptTos: 'accept_tos' in this.$route.query ? this.$route.query.accept_tos === 'true' : false,
      email: typeof this.$route.query.email === 'string' ? this.$route.query.email : '',
      referredBy: this.$route.query.organization ? 'Organization invite' : undefined,
      referredByOrganizationId: typeof this.$route.query.organization === 'string' ? this.$route.query.organization : undefined,
      referredByUserId: typeof this.$route.query.user === 'string' ? this.$route.query.user : undefined,
      sendNews: 'send_news' in this.$route.query ? this.$route.query.send_news === 'true' : true,
      userCredential: undefined as UserCredential | undefined
    },
    validationRules: {
      acceptTos: validationRules.exists,
      email: [
        ...validationRules.exists,
        ...validationRules.email
      ]
    }
  })

  get organization () {
    return this.$queryValue('organization')
  }

  get user () {
    return this.$queryValue('user')
  }

  get image () {
    if (
      this.formController.model.acceptTos &&
      this.formController.model.email
    ) {
      return 2
    } else {
      return 1
    }
  }

  get brianImages (): { image: UserRegister['image'], url: string }[] {
    // pre-render all the images for speed when switching between them
    return [
      {
        image: 1,
        url: require('../assets/brian/typing-free.png')
      },
      {
        image: 2,
        url: require('../assets/brian/complete-free.png')
      }
    ]
  }

  async created () {
    this.formController.submitHandler = async model => {
      const email = model.email.toLowerCase()
      const [, domain] = model.email.split('@')

      const firebaseUser = model.userCredential?.user || this.userModule.firebaseUser
      if (!firebaseUser) {
        await this.userModule.userLogout()

        await this.$replaceQuery({
          accept_tos: `${model.acceptTos}`,
          email,
          send_news: `${model.sendNews}`
        })

        await this.userModule.userEmailPrepare({
          email
        })

        authListener.initialize()

        await this.userModule.firebaseLogin({
          app: firebase.app,
          redirectProvider: new SAMLAuthProvider(`saml.${domain}`)
        })
      } else if (firebaseUser.email && model.email && firebaseUser.email.toLowerCase() !== email) {
        throw new Error('This account did not match the email address provided')
      } else {
        await this.userModule.userRegister({
          email,
          referredBy: model.referredBy,
          referredByOrganizationId: model.referredByOrganizationId,
          referredByUserId: model.referredByUserId
        })

        analytics.userRegister.track(this, {
          userAuthProvider: 'saml',
          userReferredBy: model.referredBy,
          userReferredByOrganizationId: model.referredByOrganizationId,
          userReferredByUserId: model.referredByUserId
        })

        if (model.sendNews) {
          await this.userModule.newsSubscribe({
            email: model.email
          })
        }

        authListener.initialize()

        await this.userModule.userLogin({
          app: firebase.app,
          user: firebaseUser
        })

        analytics.userLogin.track(this, {
          userAuthProvider: 'saml'
        })

        this.$router.push({
          name: 'organizations'
        })
      }
    }
  }

  mounted () {
    analytics.userRegisterScreen.track(this, {
      userAuthProvider: 'saml'
    })
  }

  formInteraction () {
    if (!this.formInteracted) {
      this.formInteracted = true

      analytics.userRegisterForm.track(this, {
        userAuthProvider: 'saml'
      })
      analytics.userRegister.time()
    }
  }

  async checkReferrer (referrer: string) {
    if (referrer.toLowerCase().trim() === 'other') {
      await this.$nextTick()
      this.formController.model.referredBy = ''
    }
  }

  privacyPolicyLink () {
    window.open('https://docs.google.com/document/d/1dsvVWhD7fVx4Rr7nj9sOWUwLRxVvN0fh/edit?usp=sharing&ouid=101483300304989467783&rtpof=true&sd=true', '_blank')?.focus()

    appAnalytics.privacyPolicyLink.track(this, {})
  }

  termsOfServiceLink () {
    window.open('https://docs.google.com/document/d/1lDdXZqBkGL7bKhIl-pSwW6H_Y4CDHMR4/edit?usp=sharing&ouid=101483300304989467783&rtpof=true&sd=true', '_blank')?.focus()

    appAnalytics.termsOfServiceLink.track(this, {})
  }
}
